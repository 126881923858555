import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactForm from 'react-jsonschema-form';
import { Button, Modal, Badge } from 'react-bootstrap'

import './ActivityList.css'
import 'react-datepicker/dist/react-datepicker.css'
import { getActivities, updateActivity, removeActivity } from '../services/activities'
import { getActivePatrols } from '../services/patrols'
import { filtersForm, uiFiltersForm } from './forms/filters'
import {
  computeDaysFromActivity51,
  computeDaysFromActivity54,
  computeDaysFromActivity57,
  computeDaysFromActivity60,
  computeIncubationDays,
  computeHatchSuccess,
  computeTotalSuccess
} from '../services/computed'

const ACTIVITIES_PAGE_SIZE = 10;
const EXCAVATION_ENABLED = true
const defaultFilters = {
  completed: false,
  completedF: 'false',
  // activityDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
}

const storage = window.localStorage;

// const applyFilters = (filters) => {
//   return (activity) => {
//     return Object.keys(filters).every(f => {
//       if (filters[f] === undefined) return true;
//       if (f in activity) {
//         if (['plasticNestMarker', 'woodenNestMarker', 'hNestNumber', 'activityId'].includes(f)) {
//           return activity[f].toString().includes(filters[f])
//         }
//         if (['latitude', 'longitude'].includes(f)) {
//           const minActivity = activity[f].split('º ')[1].split(',')[0].trim()
//           const minFilter = filters[f].split('º ')[1].split(',')[0].trim()

//           if (minActivity.includes(minFilter)) {
//             const decActivity = parseInt(activity[f].split(',')[1], 10)
//             const decFilter = parseInt(filters[f].split(',')[1], 10)

//             if (isNaN(decFilter)) {
//               return true
//             }
//             if (decFilter.toString().length === 1) {
//               return decFilter === Math.floor(decActivity / 100)
//             }
//             if (decFilter.toString().length === 2) {
//               const parsedDec = Math.floor(decActivity / 10)
//               return parsedDec <= decFilter + 1 && parsedDec >= decFilter - 1
//             }
//             if (decFilter.toString().length === 3) {
//               return decActivity <= decFilter + 10 && decActivity >= decFilter - 10
//             }
//           }
//         }
//       }
//       return filters[f] === activity[f]
//     })
//   }
// }

const getPatrollerName = patroller => patroller && patroller.name ? patroller.name : 'Other';

class ActivityList extends Component {

  constructor (props) {
    super(props);
    const storedFilters = JSON.parse(storage.getItem('filters'));
    this.state = {
      activities: [],
      patrols: [],
      filters: storedFilters || defaultFilters,
      show: {},
      showDelete: false,
      deleteActivityData: null,
      page: 1,
      showMoreEnabled: false,
    }

    this.toggleShow = this.toggleShow.bind(this)
    this.onChange = this.onChange.bind(this)
    this.changeCompleteness = this.changeCompleteness.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.loadActivities = this.loadActivities.bind(this)
    this.showMoreActivities = this.showMoreActivities.bind(this)
    this.filterActivities = this.filterActivities.bind(this)
  }

  componentDidMount () {
    this.loadActivities();
    const opts = {
      season: storage.getItem('season'),
      pageSize: 10000,
      page: 1,
    };

    getActivePatrols(opts).then((patrols) => {
      this.setState({ patrols })
    })

    const filtersSaved = JSON.parse(storage.getItem('filters'));
    if (filtersSaved) {
      this.setState({ filters: filtersSaved });
    }
  }

  loadActivities() {
    // TODO if filters are the same then sum else 
    const opts = {
      enhancers: ['patrol', 'patrollers'],
      filters: {
        ...this.state.filters,
        completedF: undefined,
      },
      pageSize: ACTIVITIES_PAGE_SIZE,
      page: this.state.page,
      season: storage.getItem('season'),
    };
    getActivities(opts).then((activities) => {
      this.setState({
        activities: [...this.state.activities, ...activities],
        showMoreEnabled: activities.length === ACTIVITIES_PAGE_SIZE,
      })
    });
  }

  filterActivities() {
    this.setState({
      activities: [],
      page: 1,
    }, this.loadActivities)
  }

  showMoreActivities() {
    this.setState({ page: this.state.page + 1}, this.loadActivities);
  }

  toggleShow (id) {
    if (this.state.show[id]) {
      this.setState({
        show: { ...this.state.show, [id]: !this.state.show[id] }
      })
    } else {
      this.setState({
        show: { ...this.state.show, [id]: true }
      })
    }
    // this.setState({
    //   show: this.state.show
    // });
  }

  changeCompleteness (activity, complete) {
    updateActivity(activity.id, { ...activity, completed: complete }).then(() => {
      const idx = this.state.activities.findIndex((a) => a.id === activity.id)
      const activities = this.state.activities
      activities[idx].completed = complete
      this.setState({ activities })
    })
  }

  onChange (params) {
    const filters = params.formData;
    if (params.formData.completedF === 'true') {
      filters.completed = true
    } else if (params.formData.completedF === 'false') {
      filters.completed = false
    } else {
      filters.completed = undefined
    }

    storage.setItem("filters", JSON.stringify(filters))

    this.setState({
      filters,
    })
  }

  resetFilters () {
    storage.removeItem('filters')
    this.setState({ filters: defaultFilters }, this.filterActivities);
  }

  deleteActivity () {
    removeActivity(this.state.deleteActivityData.id)
    const i = this.state.activities.findIndex((a) => a.id === this.state.deleteActivityData.id)
    const activities = this.state.activities
    activities.splice(i, 1)

    this.setState({
      activities,
      showDelete: false,
      deleteActivityData: null,
    })
  }

  handleClose () {
    this.setState({ showDelete: false })
  }

  handleShow (activity) {
    this.setState({ showDelete: true, deleteActivityData: activity })
  }

  render () {

    const { isAdmin } = this.props;
    const { activities, showDelete, patrols, filters, show, deleteActivityData } = this.state;

    return (
      <div className="ActivityList">
        <a
          className='btn btn-secondary float-right'
          href={`/api/activities/csv?season=${storage.getItem('season') || moment().format('YYYY')}`}
          target='_blank'
        >
          Export to CSV
        </a>

        <Modal show={showDelete} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Patroller</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you want to delete this activity?</p>
              {showDelete && <b>Activity ID - {deleteActivityData.activityId}</b>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose} >Cancel</Button>
            <Button variant="outline-danger" onClick={() => this.deleteActivity()} >Delete</Button>
          </Modal.Footer>
        </Modal>

        <ReactForm
          className='filters-form'
          schema={filtersForm(patrols)}
          uiSchema={uiFiltersForm}
          formData={filters}
          onChange={this.onChange}
          onError={(errors) => console.log('errors', errors)}
          showErrorList={false}
        >
          <Button className='filters-submit' type="submit" onClick={this.filterActivities}>Filter</Button>
        </ReactForm>
        <Button variant="link" onClick={this.resetFilters}>Reset filters</Button>


        <div className="list-group">
          {
            activities.length === 0 &&
            <b className="no-results">No results for the current filters</b>
          }

          {activities.map((activity) => {
            return (
              <div key={activity.id} className="list-group-item">
                <div className="row">
                  <div>
                    <div className="list-group-data">
                      <div>{activity.activityId && <Badge variant="dark">ID: {activity.activityId}</Badge>}</div>
                      <div>{activity.patrol && <Badge variant="info">{activity.patrol.code}</Badge>}</div>
                      <div>{activity.activityType} {activity.subActivityType && "-"} {activity.subActivityType}</div> {moment(activity.activityDate).format("DD/MM/YYYY")} {activity.activityTime}
                    </div>
                    <div><b>Hatchery Nest Number:</b>{activity.hNestNumber ? activity.hNestNumber : "-"}</div>
                    <div className="list-group-data">
                      <div><b>Beach:</b>{activity.beach}</div>
                      <div><b>Location:</b>{activity.location}</div>
                      {activity.coordinates && activity.coordinates.couldTakeCoordenates &&
                        <div className="list-group-data">
                          <div><b>Latitude:</b>{activity.coordinates && activity.coordinates.latitude}</div>
                          <div><b>Longitude:</b>{activity.coordinates && activity.coordinates.longitude}</div>
                        </div>
                      }
                      {activity.coordinates && !activity.coordinates.couldTakeCoordenates && <div><b>Coordinates:</b>Could not take</div>}
                    </div>
                    <div className="list-group-data">
                      <div><b>Wooden mark:</b>{activity.woodenNestMarker}</div>
                      <div><b>Plastic mark:</b>{activity.plasticNestMarker}</div>
                    </div>
                    <div><b>Observations:</b>{activity.observations}</div>
                    <div><b>Patrollers:</b>{activity.patrol && activity.patrol.patrollers.map(p => p.name).join(', ')}</div>
                  </div>
                  <div className="actions">
                    <div>
                      {activity.completed
                        ? <div className="btn-group" role="group">
                          <button type="button" className="btn btn-switch" onClick={() => this.changeCompleteness(activity, false)}>
                            Incomplete
                          </button>
                          <button type="button" className="btn btn-success" onClick={() => this.changeCompleteness(activity, true)}>
                            Complete
                          </button>
                        </div>
                        : <div className="btn-group" role="group">
                          <button type="button" className="btn btn-warning" onClick={() => this.changeCompleteness(activity, false)}>
                            Incomplete
                          </button>
                          <button type="button" className="btn btn-switch" onClick={() => this.changeCompleteness(activity, true)}>
                            Complete
                          </button>
                        </div>
                      }
                    </div>
                    <div>
                      <Button variant="link" onClick={() => this.toggleShow(activity.id)}>
                        Show more
                      </Button>
                      <Link variant="link" to={`/activity/${activity.id}`}>
                        Edit
                      </Link>

                      {isAdmin && <Button variant="link" onClick={() => this.handleShow(activity)} >Delete</Button>}
                    </div>
                    { EXCAVATION_ENABLED && <Link variant="link" to={`/activity/${activity.id}/excavation`}>
                      Add/edit excavation
                    </Link>}
                  </div>
                </div>

                {show[activity.id] &&
                  <div>
                    <hr/>
                    {activity.turtleSeen
                      ? <div>
                        <h3>Turtle seen</h3>
                        <div className="list-group-data">
                          <div><b>Tag Found L:</b>{activity.tagFoundL.tagFoundL}{activity.tagFoundL.tagFoundLRadio && activity.tagFoundL.tagFoundLRadio !== 'found' && activity.tagFoundL.tagFoundLRadio}</div>
                          <div><b>Tag Found R:</b>{activity.tagFoundR.tagFoundR}{activity.tagFoundR.tagFoundRRadio && activity.tagFoundR.tagFoundRRadio !== 'found' && activity.tagFoundR.tagFoundRRadio}</div>
                          <div><b>Turtle Name:</b>{activity.turtleName}</div>
                        </div>
                        <div className="list-group-data">
                          <div><b>PIT Tag Found:</b>{activity.pitTagFound && activity.pitTagFound.pitTagFound} {activity.pitTagFound.pitTagFoundRadio && activity.pitTagFound.pitTagFoundRadio !== 'found' && activity.pitTagFound.pitTagFoundRadio}</div>
                          <div><b>PIT New Tag:</b>{activity.pitTagNew}</div>
                          <div><b>PIT Tagged by:</b>{getPatrollerName(activity.pitTagBy)}</div>
                        </div>
                        <div className="list-group-data">
                          <div><b>CCL:</b>{activity.ccl}</div>
                          <div><b>CCW:</b>{activity.ccw}</div>
                          <div><b>DNA sample:</b>{activity.dnaSample}</div>
                        </div>
                      </div>
                      : <b>Turtle not seen</b>
                    }
                    <hr/>
                    {activity.newLocation
                      ? <div>
                        <h3>New Location</h3>
                        <div className="list-group-data">
                          <div><b>Number of eggs:</b>{activity.numberOfEggs}</div>
                          <div><b>Hatchery Nest Number:</b>{activity.hNestNumber}</div>
                          <div><b>New Location:</b>{activity.newLocation}</div>
                        </div>
                        <div className="list-group-data">
                          <div><b>Transfered By:</b>{getPatrollerName(activity.transferBy)}</div>
                          <div><b>Transfer Method:</b>{activity.transferMethod}</div>
                        </div>
                      </div>
                      : <b>Not relocated</b>}
                    <hr/>
                    {activity.excavationDate
                      ? <div>
                        <h3>Excavation</h3>
                        <div className="list-group-data">
                          <div><b>Hatch Date: </b>{activity.hatchDate}</div>
                          <div><b>Excavation Date: </b>{activity.excavationDate}</div>
                          <div><b>New egg count: </b>{activity.newEggCount}</div>
                          <div><b>Life full term: </b>{activity.lft}</div>
                          <div><b>Emerged Live: </b>{activity.emergeLive}</div>
                          <div><b>Dead Full Term: </b>{activity.dft}</div>
                          <div><b>Emerged Dead: </b>{activity.emergeDead}</div>
                          <div><b>Live Pips: </b>{activity.lp}</div>
                          <div><b>Dead Pips: </b>{activity.dp}</div>
                          <div><b>Total Unhatched: </b>{activity.totalUnhatched}</div>
                        </div>
                      </div>
                      : <b>Not excavated</b>}
                    <div className='calculated-data'>
                      <div className='list-group-data'>
                        <div><b>51 Days From Activity:</b>{computeDaysFromActivity51(activity)}</div>
                        <div><b>54 Days From Activity:</b>{computeDaysFromActivity54(activity)}</div>
                        <div><b>57 Days From Activity:</b>{computeDaysFromActivity57(activity)}</div>
                        <div><b>60 Days From Activity:</b>{computeDaysFromActivity60(activity)}</div>
                        <div><b>Incubation Days:</b>{computeIncubationDays(activity)}</div>
                        <div><b>Hatch Success:</b>{Math.round(computeHatchSuccess(activity)*100)}%</div>
                        <div><b>Total Success:</b>{Math.round(computeTotalSuccess(activity)*100)}%</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )
          }
          )}
          {this.state.showMoreEnabled &&
            <Button variant="outline-primary" onClick={this.showMoreActivities}>Show more</Button>
          }
        </div>
      </div>
    )
  }
}

export default ActivityList
