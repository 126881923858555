import React, { Component } from 'react'
import ReactForm from 'react-jsonschema-form'
import { schema, uiSchema } from './form'
import { login } from '../services/users'
import logo from '../logo-big.png'

import './Login.css'

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: false
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (params) {
    login(params.formData).then(() => {
      this.props.onLogin();
    }).catch((error) => {
      console.error('login error', error)
      this.setState({ error: true })
    })
  }

  render () {
    if (this.props.isAuthenticated) {
      return <h1> Authenticated </h1>
    }
    return (
      <div>
        <center>
          <img src={logo} alt="logo" />
        </center>
        <div className="login-container">
          {this.state.error && <div className="alert alert-danger" role="alert">
            Username and/or password invalid
          </div>}
          <div className="form-container card">
            <div className="card-body">
              <ReactForm schema={schema}
                uiSchema={uiSchema}
                onChange={(params) => console.log('change', params)}
                onSubmit={(params) => this.onSubmit(params)}
                onError={(errors) => console.log('errors', errors)}
                showErrorList={false}
              >
                <div className="login-button">
                  <button className="btn btn-primary btn-lg" type="submit">Log in</button>
                </div>
              </ReactForm>
            </div>
          </div>
          <p className="version">Version 0.1.4</p>
        </div>
      </div>
    )
  }
}

export default Login
