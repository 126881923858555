import React from 'react'
import TimeInput from 'react-time-input'
import InputMask from 'react-input-mask'
import { Typeahead } from 'react-bootstrap-typeahead';

export const timepicker = (props) => {
  return (
    <TimeInput
      initTime={props.value}
      className='form-control'
      onTimeChange={props.onChange}
      placeholder="hh:mm"
    />
  )
}

export const latitude = (props) => {
  const onChange = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <InputMask
      value={props.value}
      className='form-control'
      onChange={onChange}
      mask="16º 99,999'"
      alwaysShowMask={false}
      maskChar=' '
      permanents={[0, 1, 2, 3, 4, 7, 11]}
      type="tel"
    />
  )
}

export const longitude = (props) => {
  const onChange = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <InputMask
      value={props.value}
      className='form-control'
      onChange={onChange}
      mask="-22º 99,999'"
      alwaysShowMask={false}
      maskChar=' '
      permanents={[0, 1, 2, 3, 4, 7, 11]}
      type="tel"
    />
  )
}

export const tag = (props) => {
  const onChange = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <InputMask
      value={props.value}
      className='form-control'
      onChange={onChange}
      mask="aaa999"
      alwaysShowMask={true}
      maskChar={null}
    />
  )
}

export const patrollersAutosuggest = (props) => {
  const onChange = (value) => {
    if (value[0]) {
      props.onChange(value[0].value)
    } else {
      props.onChange(undefined)
    }
  }
  const selected = props.options.enumOptions.filter(x => x.value === props.value) || []

  return (
    <Typeahead
      id="patrollers-autosuggest"
      onChange={onChange}
      options={props.options.enumOptions}
      labelKey="label"
      selected={selected}
    />
  )
}

export default { timepicker, latitude, longitude, tag, patrollersAutosuggest }
