import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactForm from 'react-jsonschema-form';
import { Alert } from 'react-bootstrap'
import { activityForm, uiActivityForm, validateActivity } from './forms/activity';
import { nestExcavatedForm, uiExcavatedForm, excavatedValidate } from './forms/excavated';
import { newLocationForm, uiNewLocationForm, validateNewLocation } from './forms/newLocation';
import { getPatrollers } from '../services/patrollers'
import { turtleForm, uiTurtleForm } from './forms/turtle';
import { observationsForm, uiObservationsForm } from './forms/observations';
import './ActivityForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import { createActivity, updateActivity, getActivity } from '../services/activities';
import { computeEmergedAlive, computeNewEggsCount, computeTotalUnhatched } from '../services/computed';

const storage = window.localStorage

const CustomFieldTemplate = (props) => {
  const { id, classNames, label, help, required, description, errors, children, schema } = props
  const asterisk = <span className="required">*</span>
  const showLabel = schema.type !== 'object';

  return (
    <div className={`${classNames} ${id}`}>
      {showLabel && <label htmlFor={id}>{label}{required ? asterisk : null}</label>}
      {description}
      {children}
      {errors}
      {help}
    </div>
  )
}

const cleanData = (data) => {
  if(data.activityType !== "NEST") {
    data.newLocation = undefined
  }
  if(!data.turtleSeen) {
    data.tagFoundL = undefined
    data.tagFoundR = undefined
    data.tagNewL = undefined
    data.tagNewR = undefined
    data.tagByL = undefined
    data.tagByR = undefined
    data.pitTagFound = undefined
    data.pitTagNew = undefined
    data.pitTagBy = undefined
    data.ccl = undefined
    data.ccw = undefined
    data.parasites = undefined
    data.dnaSample = undefined
    data.pitTagFound = undefined
    data.tagFoundL = undefined
    data.tagFoundR = undefined
  }
  if(!data.newLocation) {
    data.numberOfEggs = undefined
    data.eggsbroken = undefined
    data.transferDuration = undefined
    data.dayOfTransfer = undefined
    data.startTransfer = undefined
    data.endTransfer = undefined
    data.transferBy = undefined
    data.transferMethod = undefined
    data.transferReason = undefined
    data.eggsTakenWhileNesting = undefined

    data.originalTec = undefined
    data.tsTec = undefined
    data.originalBec = undefined
    data.tsBec = undefined
    data.originalWecT = undefined
    data.tsWecT = undefined
    data.originalWecB = undefined
    data.tsWecB = undefined
  }
  if(data.newLocation !== 'TS') {
    data.tsBeach = undefined
    data.tsLatitude = undefined
    data.tsLongitude = undefined
    data.tsHwm = undefined
    data.tsGpsNumber = undefined
    data.tsWaypoint = undefined
  }
  if(!['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(data.newLocation)) {
    data.hNestNumber = undefined
  }
  if(!data.coordinates.couldTakeCoordenates) {
    data.coordinates.latitude = undefined
    data.coordinates.longitude = undefined
  }
  if(data.pitTagFound && data.pitTagFound.pitTagFoundRadio !== "found") {
    data.pitTagFound.pitTagFound = undefined
  }
  if(data.tagFoundL && data.tagFoundL.tagFoundLRadio !== "found") {
    data.tagFoundL.tagFoundL = undefined
  }
  if(data.tagFoundR && data.tagFoundR.tagFoundRRadio !== "found") {
    data.tagFoundR.tagFoundR = undefined
  }
  if(!data.beach) {
    data.location = undefined
  }
  return data;
}

class ActivityForm extends Component {
  constructor (props) {
    super(props);
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    this.state = {
      id: null,
      data: {
        activityDate: yesterday,
        dayOfTransfer: today,
      },
      form: 'activity',
      schema: activityForm,
      uiSchema: uiActivityForm,
      validation: validateActivity,
      formsList: [{
        form: 'activity',
        schema: activityForm,
        uiSchema: uiActivityForm,
        validation: validateActivity
      }, {
        form: 'observations',
        schema: observationsForm,
        uiSchema: uiObservationsForm,
        validation: (formData, errors) => errors
      }],
      patrollers: [],
      showAlert: false,
      showSuccess: false,
    }
    console.log(this.state.data)

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBackClick = this.onBackClick.bind(this)
  }

  async componentWillMount () {
    const patrollers = await getPatrollers({season: storage.getItem('season')});
    patrollers.push({id: 'other', name: 'Other'});

    if (this.props.match.params.id) {
      try {
        const data = await getActivity(this.props.match.params.id)
        const state = { data, id: data.id, patrollers }
        if (this.props.match.params.form === 'excavation' ) {
          state.formsList = [{
            form: 'excavation',
            schema: nestExcavatedForm,
            uiSchema: uiExcavatedForm,
            validation: excavatedValidate,
          }];
          state.form = 'excavation';
          state.schema = nestExcavatedForm;
          state.uiSchema = uiExcavatedForm;
          state.validation = excavatedValidate;
        }
        console.log("state", state)
        this.setState(state)
      } catch (err) {
        alert('Activity not found');
        this.props.history.push('/activities');
      }
    } else {
      this.setState({ patrollers })
    }
  }

  onChange (params) {

    const { fragments, lp, dp, uhne, uhe, ffe, lft, dft, predated, activityDate, emergedDead, undetermined, excavationDate } = params.formData;

    // Day of transfer is activityDate + 1 by default
    const dayOfTransfer = moment(activityDate).add(1, "days").format('YYYY-MM-DD');

    const excavated = !!excavationDate;

    // Excavations calculated fields
    const emergeLive = computeEmergedAlive({ fragments, lft, dft })
    const newEggCount = computeNewEggsCount({ fragments, lp, dp, uhne, uhe, ffe, predated, undetermined })
    const unhatched = computeTotalUnhatched({ uhne, uhe, ffe, predated, undetermined })

    this.setState({
      data: { ...this.state.data, ...params.formData, newEggCount, dayOfTransfer, emergeLive, totalUnhatched: unhatched, excavated }
    })
  }

  async onSubmit (params) {
    let list = this.state.formsList

    // storage.setItem(this.state.form, JSON.stringify(params.formData))

    console.log("this.state.form", this.state.form)
    if (this.state.form === 'activity') {
      list = [{
        form: 'activity',
        schema: activityForm,
        uiSchema: uiActivityForm,
        validation: validateActivity
      }, {
        form: 'observations',
        schema: observationsForm,
        uiSchema: uiObservationsForm,
        validation: (formData, errors) => errors
      }]

      if (params.formData.newLocation) {
        list.splice(list.length - 1, 0, {
          form: 'newLocation',
          schema: newLocationForm(params.formData.newLocation, this.state.patrollers),
          uiSchema: uiNewLocationForm(params.formData.newLocation),
          validation: validateNewLocation
        })
      }
      if (params.formData.turtleSeen) {
        const transformErrors = (errors) => {
          console.log(errors)
          return errors.reduce((acc, error) => {
            if (error.name === 'pattern') {
              if (error.property.indexOf('.tag') !== -1) {
                error.message = 'Expected 3 letters followed by 3 numbers';
              } else if (error.property.indexOf('.pitTag') !== -1) {
                error.message = 'Expected 15 digits, or 9 digits and a capital letter';
              }
              acc.push(error);
            }
            return acc;
          }, []);
        };

        list.splice(list.length - 1, 0, {
          form: 'turtleForm',
          schema: turtleForm(this.state.patrollers),
          uiSchema: uiTurtleForm,
          transformErrors: transformErrors,
          validation: (formData, errors) => errors
        })
      }
    }

    const currentForm = list.find((f, i) => f.form === this.state.form)
    const index = list.indexOf(currentForm)

    const newIndex = index + 1

    let showAlert = false;
    let showSuccess = false;

    // If last step
    if (newIndex === list.length) {
      let data = { ...this.state.data, ...params.formData };
      data = cleanData(data)
      const { patrolId } = this.props.match.params;
      try {
        if (this.state.id) {
          await updateActivity(this.state.id, data);
        } else {
          await createActivity({ ...data, patrolId });
        }
        // storage.removeItem('activity')
        // storage.removeItem('newLocation')
        // storage.removeItem('turtleForm')
        // storage.removeItem('nestExcavatedForm');
        showSuccess = true;
        setTimeout(() => {
          if (this.createAnother) {
            this.props.history.push(`/patrol/${patrolId}/activity/new`);
          } else {
            this.props.history.push('/activities');
          }
        }, 1000);
      } catch(e) {
        showAlert = true;
      }
    }
    const nextForm = list[newIndex]
    this.setState({ ...nextForm, formsList: list, showAlert, showSuccess, data: { ...this.state.data, ...params.formData } })
    window.scrollTo(0, 0)
  }

  onBackClick (data) {
    const currentForm = this.state.formsList.find((f, i) => f.form === this.state.form)
    const index = this.state.formsList.indexOf(currentForm)
    const prevForm = this.state.formsList[index - 1]

    this.setState({ ...prevForm })
  }

  render () {
    // const data = this.state.id ? this.state.data : (storage[this.state.form] ? JSON.parse(storage[this.state.form]) : {})
    // const data = this.state.id ? this.state.data : {}
    const { data, showSuccess, showAlert } = this.state;

    const isLastStep = this.state.form === 'observations';

    const buttonText = isLastStep ? 'Submit' : 'Next'

    console.log('this.state.form', this.state.form)

    return (
      <div className="Form">
        <div>
          {showAlert && <Alert variant="danger">
            Oops! The activity could not be saved! Please check your internet conection and submit again
          </Alert>}
          {showSuccess && <Alert variant="success">
            Activity successfully saved!
          </Alert>}
          {(this.state.form === 'activity' || this.state.form === 'excavation') &&
            <Link className="btn btn-link" to="/activities">&lt;-- Back to activities list</Link>
          }
          {(this.state.form !== 'activity' && this.state.form !== 'excavation') &&
            <button className="btn btn-link" onClick={(data) => this.onBackClick(data)}>&lt;-- Back</button>
          }
          <ReactForm
            schema={this.state.schema}
            uiSchema={this.state.uiSchema}
            formData={data}
            FieldTemplate={CustomFieldTemplate}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            onError={(errors) => console.log('errors', errors)}
            validate={this.state.validation}
            transformErrors={this.state.transformErrors}
            showErrorList={false}
            liveValidate
          >
            <button className="btn btn-primary" type="submit" disabled={showSuccess}>{buttonText}</button>
            {this.state.form === 'observations' && this.props.match.params.patrolId &&
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  marginLeft: '10px',
                }}
                onClick={() => this.createAnother = true}
                disabled={showSuccess}
              >
                Submit and create another
              </button>
            }
          </ReactForm>
        </div>
      </div>
    )
  }
}

export default withRouter(ActivityForm);
