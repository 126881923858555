import {
  buildBeachDependencies,
  OPTIONS,
  getLabels
} from '../../utils/options'

import { latitude, longitude } from '../../utils/widgets'

export const beachDependencies = buildBeachDependencies('beach', 'location')

export const filtersForm = (patrols) => {
  return {
    type: 'object',
    properties: {
      completedF: {
        'title': 'Completed',
        'type': 'string',
        'enum': ['true', 'false'],
        'enumNames': ['Yes', 'No']
      },
      excavated: {
        'title': 'Exacavated',
        'type': 'string',
        'enum': ['true'],
        'enumNames': ['Yes']
      },
      activityId: {
        'title': 'Activity ID',
        'type': 'string'
      },
      activityDate: {
        'title': 'Activity Date',
        'type': 'string',
        'format': 'date'
      },
      activityType: {
        'title': 'Activity Type',
        'type': 'string',
        'enum': OPTIONS.ACTIVITY_TYPES,
        'enumNames': getLabels("ACTIVITY_TYPES")
      },
      subActivityType: {
        'title': 'Sub-activity Type',
        'type': 'string',
        'enum': OPTIONS.SUBACTIVITY_TYPES,
        'enumNames': getLabels("SUBACTIVITY_TYPES")
      },
      beach: {
        'title': 'Beach',
        'type': 'string',
        'enum': OPTIONS.BEACH_OPTIONS,
        'enumNames': getLabels("BEACH_OPTIONS")
      },
      newLocation: {
        'title': 'New Location',
        'type': 'string',
        'enum': OPTIONS.NEW_LOCATIONS,
        'enumNames': getLabels("NEW_LOCATIONS")
      },
      hNestNumber: {
        'title': 'Hatchery Nest Number',
        'type': 'string'
      },
      plasticNestMarker: {
        'title': 'Plastic Nest Number',
        'type': 'string'
      },
      woodenNestMarker: {
        'title': 'Wooden Nest Marker',
        'type': 'string'
      },
      patrolId: {
        title: 'Patrol',
        type: 'string',
        enum: patrols.map(p => p.id),
        enumNames: patrols.map(p => p.code)
      },
      latitude: {
        'title': 'Latitude',
        'type': 'string'
      },
      longitude: {
        'title': 'Longitude',
        'type': 'string'
      }
    },
    'dependencies': {
      'beach': {
        'oneOf': beachDependencies
      }
    }
  }
}

export const uiFiltersForm = {
  'ui:order': ['completedF', 'activityDate', 'activityType',
    'subActivityType', 'beach', '*',
    'newLocation'
  ],
  latitude: {
    'ui:widget': latitude
  },
  longitude: {
    'ui:widget': longitude
  }
}

export default { filtersForm, uiFiltersForm }
