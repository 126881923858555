export const schema = {
  'type': 'object',
  'properties': {
    username: { type: 'string', title: 'User' },
    'password': {
      'type': 'string',
      'title': 'Password'
    }
  }
}

export const uiSchema = {
  'ui:order': ['username', 'password'],
  'password': {
    'ui:widget': 'password'
  },
}

export default { schema, uiSchema }
