import React from 'react';

import { Form } from 'react-bootstrap'
import moment from 'moment';

class SeasonSelector extends React.Component {
  constructor(props){
    super(props);
    this.storage = window.localStorage;
    this.handleSeasonSelector = this.handleSeasonSelector.bind(this);
  }
  
  handleSeasonSelector(e){
    this.storage.setItem("season", this.inputEl.value);
    window.location.reload();
  }

  generateOptions = () => {
    const currentYear = moment().format('YYYY');
    const options = [];
    for (let i = currentYear; i >= 2019; i--) {
      options.push(<option value={i} key={i}>Season {i}</option>);
    }
    return options;
  }

  render(){
    return (
      <div>
        <Form>
          <Form.Control as="select"
            onChange={this.handleSeasonSelector}
            ref={ el => this.inputEl=el }
            defaultValue={this.storage.getItem('season')}>
              {this.generateOptions()}
          </Form.Control>
        </Form>
      </div>
    )
  }
}

export default SeasonSelector;