import {
  buildBeachDependencies,
  OPTIONS,
  getLabels
} from '../../utils/options'
import { coordinates, checkCoordInRange } from '../../utils/coordinates'
import { timepicker, latitude, longitude, patrollersAutosuggest } from '../../utils/widgets'

export const validateNewLocation = (formData, errors) => {
  if (formData.tsBeach && formData.tsSection) {
    const locationCoordinates = coordinates[formData.tsBeach][formData.tsSection];
    if (locationCoordinates) {
      checkCoordInRange(formData.tsLatitude, locationCoordinates.latitude, errors.tsLatitude);
      checkCoordInRange(formData.tsLongitude, locationCoordinates.longitude, errors.tsLongitude);
    }
  }
  return errors;
}

const isHlocation = (location) => ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(location)

const newLocationFormFields = (newLocation, patrollers) => {
  let fields = {
    numberOfEggs: {
      'title': 'Number of eggs',
      'type': 'integer',
      'multipleOf': 1,
    },
    eggsbroken: {
      'title': 'Eggs broken',
      'type': 'integer',
      'multipleOf': 1,
    },
    transferDuration: {
      'title': 'Transfer Duration',
      'type': 'string',
      'enum': OPTIONS.TRANSFER_DURATIONS,
      'enumNames': getLabels("TRANSFER_DURATIONS")
    },
    dayOfTransfer: {
      'title': 'Day of transfer',
      'format': 'date',
      type: 'string'
    },
    startTransfer: {
      'title': 'Start transfer time',
      type: 'string'
    },
    endTransfer: {
      'title': 'End transfer time',
      type: 'string'
    },
    transferBy: {
      'title': 'Transfered By',
      'type': 'string',
      'enum': patrollers.map(x => x.id),
      'enumNames': patrollers.map(x => x.name)
    },
    transferMethod: {
      'title': 'Transfer Method',
      'type': 'string',
      'enum': OPTIONS.TRANSFER_METHODS,
      'enumNames': getLabels("TRANSFER_METHODS")
    },
    transferReason: {
      'title': 'Transfer Reason',
      'type': 'string',
      'enum': OPTIONS.TRANSFER_REASONS,
      'enumNames': getLabels("TRANSFER_REASONS")
    },
    eggsTakenWhileNesting: {
      'title': 'Eggs taken while nesting',
      'type': 'number',
      'enum': [0, 1],
      'enumNames': ['No', 'Yes']
    }
  }

  if (isHlocation(newLocation)) {
    fields = Object.assign({
      hNestNumber: {
        'title': 'Hatchery Nest Number',
        'type': 'integer'
      },
      originalTec: {
        'title': 'Original TEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsTec: {
        'title': 'TS New TEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalBec: {
        'title': 'Original BEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsBec: {
        'title': 'TS New BEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalWecT: {
        'title': 'Original WEC-T',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsWecT: {
        'title': 'TS New WEC-T',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalWecB: {
        'title': 'Original WEC-B',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsWecB: {
        'title': 'TS New WEC-B',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
    }, fields);
  } else if (newLocation === 'TS') {
    Object.assign(fields, {
      tsBeach: {
        'title': 'TS New Beach',
        'type': 'string',
        'enum': OPTIONS.BEACH_OPTIONS,
        'enumNames': getLabels("BEACH_OPTIONS")
      },
      tsLatitude: {
        'title': 'TS New Latitude',
        'type': 'string'
      },
      tsLongitude: {
        'title': 'TS New Longitude',
        'type': 'string'
      },
      tsHwm: {
        'title': 'TS New High Water Mark',
        'type': 'number'
      },
      originalTec: {
        'title': 'Original TEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsTec: {
        'title': 'TS New TEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalBec: {
        'title': 'Original BEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsBec: {
        'title': 'TS New BEC',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalWecT: {
        'title': 'Original WEC-T',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsWecT: {
        'title': 'TS New WEC-T',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      originalWecB: {
        'title': 'Original WEC-B',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsWecB: {
        'title': 'TS New WEC-B',
        'type': 'integer',
        'minimum': 0,
        'maximum': 100,
      },
      tsGpsNumber: {
        'title': 'TS GPS Number',
        'type': 'string',
        'enum': OPTIONS.GPS_OPTIONS,
        'enumNames': getLabels("GPS_OPTIONS")
      },
      tsWaypoint: {
        'title': 'TS Waypoint',
        'type': 'number'
      }
    })
  }
  return fields
}

export const newBeachDependencies = buildBeachDependencies('tsBeach', 'tsSection', 'TS New Location')

export const newLocationForm = (newLocation, patrollers) => {
  const properties = newLocationFormFields(newLocation, patrollers)
  return {
    title: 'New Location',
    type: 'object',
    required: [],
    properties: properties,
    'dependencies': {
      'tsBeach': {
        'oneOf': newBeachDependencies
      }
    }
  }
}

export const uiNewLocationForm = (newLocation) => {
  let order = ['*']
  if (isHlocation(newLocation)) {
    order = ['*']
  } else if (newLocation === 'TS') {
    order = [
      'numberOfEggs',
      'eggsbroken',
      'transferDuration',
      'dayOfTransfer',
      'startTransfer',
      'endTransfer',
      'transferBy',
      'transferMethod',
      'transferReason',
      'eggsTakenWhileNesting',
      'tsBeach',
      '*',
      'tsLatitude',
      'tsLongitude',
      'tsHwm',
      'originalTec',
      'tsTec',
      'originalBec',
      'tsBec',
      'originalWecT',
      'tsWecT',
      'originalWecB',
      'tsWecB',
      'tsGpsNumber',
      'tsWaypoint',
    ]
  }
  return {
    'ui:order': order,
    startTransfer: {
      'ui:widget': timepicker
    },
    endTransfer: {
      'ui:widget': timepicker
    },
    tsLatitude: {
      'ui:widget': latitude
    },
    tsLongitude: {
      'ui:widget': longitude
    },
    transferBy: {
      'ui:widget': patrollersAutosuggest
    },
    hNestNumber: {
      "ui:options": {
        "inputType": "number",
      }
    },
    numberOfEggs: {
      "ui:options": {
        "inputType": "number",
      }
    },
    eggsbroken: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsHwm: {
      "ui:options": {
        "inputType": "number",
      }
    },
    originalTec: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsTec: {
      "ui:options": {
        "inputType": "number",
      }
    },
    originalBec: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsBec: {
      "ui:options": {
        "inputType": "number",
      }
    },
    originalWecT: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsWecT: {
      "ui:options": {
        "inputType": "number",
      }
    },
    originalWecB: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsWecB: {
      "ui:options": {
        "inputType": "number",
      }
    },
    tsWaypoint: {
      "ui:options": {
        "inputType": "number",
      }
    }
  }
}

export default { uiNewLocationForm, newLocationForm }
