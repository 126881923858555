import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactForm from 'react-jsonschema-form';
import { schema, uiSchema } from './form';
import { getPatrollers } from '../services/patrollers';
import { createPatrol } from '../services/patrols';

import './PatrolForm.css'

const storage = window.localStorage;

class PatrolForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      surveyDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      allPatrollers: [],
      patrollers: [''],
      code: '',
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillMount () {
    getPatrollers({season: storage.getItem('season')}).then(allPatrollers => this.setState({ allPatrollers }))
  }

  onChange (params) {
    const { patrollers, surveyDate, code } = params.formData;
    this.setState({ patrollers, surveyDate, code });
  }

  onSubmit (params) {
    const patrol = {
      surveyDate: this.state.surveyDate,
      patrollerIds: [...new Set(this.state.patrollers.filter(m => m))],
      code: this.state.code
    }
    createPatrol(patrol).then(({ id }) => {
      this.props.history.push(`/patrol/${id}/activity/new`);
    })
  }

  render () {
    const { allPatrollers, patrollers, surveyDate, code } = this.state
    return (
      <div className="patrol-form-container">
        <b>Survey Date</b>
        <ReactForm schema={schema(allPatrollers)}
          uiSchema={uiSchema}
          formData={{ patrollers, surveyDate, code }}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onError={(errors) => console.log('errors', errors)}
          showErrorList={false}
        >
          <button className="btn btn-primary" type="submit">Create Patrol</button>
        </ReactForm>
      </div>
    )
  }
}

export default withRouter(PatrolForm);
