import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.css'
import * as Sentry from '@sentry/browser';
import App from './App/App'

Sentry.init({dsn: "https://8e2049e2950e4c42aa9d2736a4b817e5@sentry.io/1509201"});

ReactDOM.render((
  <HashRouter>
    <App />
  </HashRouter>
), document.getElementById('root'))
