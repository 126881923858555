export const nestExcavatedForm = {
  title: 'Excavated Nest',
  type: 'object',
  required: [],
  properties: {
    excavationDate: {
      title: 'Excavation Date',
      'format': 'date',
      type: 'string'
    },
    hatchDate: {
      'title': 'Hatch date',
      'format': 'date',
      type: 'string'
    },
    fragments: {
      title: 'Egg Fragments',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000, 
    },
    lft: {
      title: 'Live Full Term',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    dft: {
      title: 'Dead Full Term',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    lp: {
      title: 'Live Pips',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    dp: {
      title: 'Dead Pips',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    emergeDead: {
      title: 'Emerged dead',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    "emergeLive": {
      "title": "Emerged live",
      "type": "number"
    },
    uhne: {
      title: 'Unhatched No-Embryo Egg',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    uhe: {
      title: 'Unhatched Embryo Egg',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    ffe: {
      title: 'Fully-Formed Embryo',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    predated: {
      title: 'Predated',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    undetermined: {
      title: 'Undetermined',
      'type': 'number',
      'minimum': 0,
      'maximum': 1000,
    },
    "totalUnhatched": {
      "title": "Total Unhatched",
      "type": "number"
    },
    "newEggCount": {
      "title": "New Egg Count",
      "type": "number",
    },
    hatchNotes: {
      title: 'Hatch Notes',
      'type': 'string'
    },
    tracks: {
      title: 'Tracks (%)',
      type: 'object',
      properties: {
        tracksA: {
          title: 'A',
          'type': 'number',
          'minimum': 0,
          'maximum': 100,
        },
        tracksB: {
          title: 'B',
          'type': 'number',
          'minimum': 0,
          'maximum': 100,
        },
        tracksC: {
          title: 'C',
          'type': 'number',
          'minimum': 0,
          'maximum': 100,
        },
        tracksD: {
          title: 'D',
          'type': 'number',
          'minimum': 0,
          'maximum': 100,
        },
      }
    },
    exhwm: {
      title: 'High Water Mark',
      'type': 'number',
    },
    exbec: {
      title: 'BEC',
      'type': 'number',
      'minimum': 0,
      'maximum': 100,
    },
    bw: {
      title: 'BW',
      'type': 'number',
      'minimum': 0,
      'maximum': 100,
    }
  }
}

export const uiExcavatedForm = {
  hatchNotes: {
    'ui:widget': 'textarea'
  },
  totalUnhatched: {
    "ui:disabled": true,
  },
  emergeLive: {
    "ui:disabled": true,
  },
  newEggCount: {
    "ui:disabled": true,
  },
}

export const excavatedValidate = (formData, errors) => {
  if (formData.tracks.tracksA && formData.tracks.tracksB && formData.tracks.tracksC && formData.tracks.tracksD) {
    if (formData.tracks.tracksA + formData.tracks.tracksB + formData.tracks.tracksC + formData.tracks.tracksD !== 100) {
      errors.tracks.addError('The sum of tracks must be 100%')
    }
  }
  return errors
}

export default { nestExcavatedForm, uiExcavatedForm, excavatedValidate }
