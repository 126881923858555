import React, { Component } from 'react'
import ReactForm from 'react-jsonschema-form'
import { schema, uiSchema } from './form'
import { getPatrollers, createPatroller, removePatroller } from '../services/patrollers'
import { Button, Modal } from 'react-bootstrap'

import './Patrollers.css'

const storage = window.localStorage;

class Patrollers extends Component {
  constructor (props) {
    super(props)

    this.state = {
      patrollers: [],
      show: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount () {
    getPatrollers({season: storage.getItem('season')}).then((patrollers) => {
      this.setState({ patrollers })
    })
  }

  onSubmit (params) {
    console.log('onSubmit', params)
    createPatroller(params.formData).then((patroller) => {
      this.state.patrollers.push({ id: patroller.id, name: params.formData.name })
      this.setState({ patrollers: this.state.patrollers })
      this.handleClose()
    })
  }

  deletePatroller (id) {
    removePatroller(id)
    const i = this.state.patrollers.findIndex((x) => x.id === id)
    const patrollers = this.state.patrollers
    patrollers.splice(i, 1)

    this.setState({
      patrollers
    })
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return (
      <div>
        <Button variant="primary" className="new-button" onClick={this.handleShow}>
          New Patroller
        </Button>

        <div className="list-group">
          {this.state.patrollers.sort((a,b) => {
            return (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0;
          }).map((patroller) => {
            return (
              <div key={patroller.id} className="list-group-item">
                <div>{patroller.name}</div>
                <div><Button variant="outline-danger" onClick={() => this.deletePatroller(patroller.id)}>Delete</Button></div>
              </div>
            )
          }
          )}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Patroller</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactForm schema={schema}
              uiSchema={uiSchema}
              onChange={(params) => console.log('change', params)}
              onSubmit={this.onSubmit}
              onError={(errors) => console.log('errors', errors)}
              showErrorList={false}
            >
              <Button type="submit">Create</Button>
            </ReactForm>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default Patrollers
