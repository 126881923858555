import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo-big.png'
import './Home.css'

class Home extends Component {
  render () {
    return (
      <div>
        <center>
          <img src={logo} alt="logo" />
        </center>
        <div className="menu">
          <div>
            <Link to="/patrol/new" className="btn btn-lg btn-primary">New Patrol</Link>
          </div>
          <div>
            <Link to="/patrols" className="btn btn-lg btn-secondary">Patrols</Link>
          </div>
          <div>
            <Link to="/activities" className="btn btn-lg btn-secondary">Activities</Link>
          </div>
          <p>Version 0.1.4</p>
        </div>
      </div>
    )
  }
}

export default Home
