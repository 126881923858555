export const schema = {
  'type': 'object',
  'properties': {
    name: { type: 'string', title: 'Name' }
  }
}

export const uiSchema = {
  'ui:order': ['name']
}

export default { schema, uiSchema }
