import { request, filterSeason } from './api'

export const getPatrollers = async ({season}) =>
  request(filterSeason('/api/patrollers', season));

export const getPatroller = async (id) =>
  request(`/api/patrollers/${id}`)

export const createPatroller = async (data) =>
  request('/api/patrollers', { method: 'POST', body: JSON.stringify(data) })

export const updatePatroller = async (id, data) =>
  request(`/api/patrollers/${id}`, { method: 'PUT', body: JSON.stringify(data) })

export const removePatroller = async (id) =>
  request(`/api/patrollers/${id}`, { method: 'DELETE' })

export default { getPatrollers, getPatroller, createPatroller, updatePatroller, removePatroller }
