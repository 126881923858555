import { request, enhanceUrl, paginate, filterSeason } from './api';

export const getPatrols = async ({ enhancers } = {}) =>
  request(enhanceUrl('/api/patrols', enhancers));

export const getActivePatrols = async ({ enhancers, pageSize, page, season } = {}) =>
  request(
    paginate(
      filterSeason(enhanceUrl('/api/patrols?archived=false', enhancers), season),
      pageSize,
      page,
    )
  );

export const getPatrol = async (id, { enhancers } = {}) =>
  request(enhanceUrl(`/api/patrols/${id}`, enhancers));

export const createPatrol = async (data) =>
  request('/api/patrols', { method: 'POST', body: JSON.stringify(data) });

export const updatePatrol = async (id, data) =>
  request(`/api/patrols/${id}`, { method: 'PUT', body: JSON.stringify(data) });

export const removePatrol = async (id) =>
  request(`/api/patrols/${id}`, { method: 'DELETE' });

export const archivePatrol = async (id) =>
  request(`/api/patrols/${id}/archive`, { method: 'POST' });

export default {
  getPatrols,
  getActivePatrols,
  getPatrol,
  createPatrol,
  updatePatrol,
  removePatrol,
  archivePatrol,
};
