export const LABELS = {  
   "ACTIVITY_TYPES":{  
      "FCU": "FCU – False Crawl U-turn",
      "FCA": "FCA – False Crawl Attempt",
      "NEST": "NEST",
      "OTHER": "OTHER"
   },
   "SUBACTIVITY_TYPES": {  
      "P": "PREDATED",
      "D": "DEAD",
      "S": "SAVED"
   },
   "BEACH_OPTIONS":{  
      "CF": "CF – Costa Fragata",
      "SN": "SN – Serra Negra",
      "BN": "BN – Baía Negra",
      "AL": "AL - Agodoeiro",
      "ML": "ML – Monte Leão",
      "PP": "PP – Ponta Preta",
      "SM": "SM – Santa Maria",
      "WESC": "WESC – West Coast",
      "EASC": "EASC – East Coast",
      "MUD": "MUD - Murdeira",
      "NTH": "NTH - North",
      "PdL": "PdL – Pedra de Lume"
   },
   "LOCATIONS":{  
      "CF": {
         "IG": "IG - Igrejinha",
         "PJ": "PJ – Ponta Jelonga",
         "S": "S - Salinas",
         "CS": "CS – Cabeça de Salinas",
         "KB": "KB – Kite Beach"
      },
      "SN": {
         "B1": "B1 – Baía 1",
         "B2": "B2 – Baía 2",
         "B3": "B3 – Baía 3",
         "B4": "B4 – Baía 4",
      },
      "BN": {
         "BN1": "BN1 – Baía Negra 1",
         "BN2": "BN2 - Baía Negra 2",
         "BN3": "BN3 - Baía Negra 3"
      },
      "AL": {
         "BB": "BB – Black Sand Beach",
         "PB": "PB – Paradise Beach",
         "T": "T - Tortuga",
      },
      "ML": {
         "ML": "ML – Monte Leão",
         "MAD": "MAD - Madama"
      },
      "PP": {
         "PP": "PP – Ponta Preta",
         "RIU": "RIU",
      },
      "SM": {
         "PS": "PS – Ponta Sinó",
         "SM": "SM – Santa Maria",
         "PO": "PO – Pontão",
         "AS": "AS – António Sousa",
      },
      "WESC": {
         "CAL": "CAL - Calhetinha",
         "CFUND1": "CFUND1 – Calheta Funda 1",
         "CFUND2": "CCFUND2 - Calheta Funda 2",
         "PRS": "PRS - Prainhas",
      },
      "EASC": {
         "PAR": "PAR - Parda",
         "PF": "PF – Pedra Frada",
         "RdT1": "RdT1 – Ribeira de Tarrafe 1",
         "RdT2": "RdT2 – Ribeira de Tarrafe 2",
         "RdT3": "RdT3 – Ribeira de Tarrafe 3",
         "PC1": "PC1 – Praia Chano 1",
         "PC2": "PC2 – Praia Chano 2"
      },
      "PdL": {
         "PdL": "PdL – Pedra de Lume",
         "FJ": "FJ - Feijoal",
         "TC": "TC – Trás Cruz",
      },
      "MUD": {
         "MUD1": "MUD1 – Murdeira 1",
         "MUD2": "MUD2 – Murdeira 2",
         "PR": "PR - Prainha",
         "PR2": "PR2 – Prainha 2",
      },
      "NTH": { 
         "JP": "JP – Joaquin Petinha",
         "FON": "FON - Fontona",
         "PAL": "PAL - Palmeira",
         "CADJ": "CADJ - Cadjetinha",
         "FU": "FU - Furna"
      },
   },
   "GPS_OPTIONS":{  
      "OC": "ON CALL",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "8": "8",
      "9": "9",
      "10": "10",
      "11": "11",
      "12": "12",
      "13": "13"
   },
   "NEW_LOCATIONS":{  
      "H1": "H1 - RIU",
      "H2": "H2 - Kite Beach",
      "H3": "H3 - Pedra de Lume",
      "H4": "H4",
      "H5": "H5",
      "H6": "H6",
      "TS": "TS – Trans situ"
   },
   "TRANSFER_METHODS":{
      "Walk": "WALK",
      "Quad": "QUAD",
      "Car": "CAR"
   },
   "TRANSFER_REASONS":{  
      "Light": "LIGHT",
      "HWM": "HWM",
      "Flooding": "FLOODING",
      "Predation": "PREDATION",
      "Others": "OTHER"
   },
   "TRANSFER_DURATIONS":{  
      "A": "A: <2h",
      "B": "B: 2-10h",
      "C": "C: 10-24h",
      "D": "D: >24h"
   }
}

export default { LABELS };
