export const checkCoordInRange = (value, range, fieldErrors) => {
  if(value) {
    const coord = parseInt(value.replace(/[^0-9]/g, ''));
    if(range.min !== -1 && range.max !== -1) {
	    if(coord < range.min || coord > range.max) {
	      fieldErrors.addError(`Coordinate should be between ${range.min} and ${range.max} for the selected location.`);
	    }
	  }
  }
}

// Verify coordinates correctness
// Object.values(coordinates).map(o => Object.values(o)).flat().map(o => Object.values(o)).flat().filter(o => o.min >= o.max)
export const coordinates = {
	SN: {
		B4: {
			latitude: {
				min: 1639105,
				max: 1639277,
			},
			longitude: {
				min: 2253227,
				max: 2253266,
			},
		},
		B3: {
			latitude: {
				min: 1638846,
				max: 1639118,
			},
			longitude: {
				min: 2253218,
				max: 2253283,
			},
		},
		B2: {
			latitude: {
				min: 1638700,
				max: 1638846,
			},
			longitude: {
				min: 2253158,
				max: 2253247,
			},
		},
		B1: {
			latitude: {
				min: 1638592,
				max: 1638714,
			},
			longitude: {
				min: 2253162,
				max: 2253287,
			},
		},
	},
	BN: {
		BN1: {
			latitude: {
				min: 1638570,
				max: 1638648,
			},
			longitude: {
				min: 2253286,
				max: 2253385,
			},
		},
		BN2: {
			latitude: {
				min: 1638557,
				max: 1638620,
			},
			longitude: {
				min: 2253419,
				max: 2253600,
			},
		},
		BN3: {
			latitude: {
				min: 1638282,
				max: 1638557,
			},
			longitude: {
				min: 2253600,
				max: 2253940,
			},
		},
	},
	CF: {
		KB: {
			latitude: {
				min: 1637500,
				max: 1638282,
			},
			longitude: {
				min: 2253939,
				max: 2254300,
			},
		},
		CS: {
			latitude: {
				min: 1636885,
				max: 1637500,
			},
			longitude: {
				min: 2253865,
				max: 2254300,
			},
		},
		S: {
			latitude: {
				min: 1636450,
				max: 1636885,
			},
			longitude: {
				min: 2253599,
				max: 2254001,
			},
		},
		PJ: {
			latitude: {
				min: 1636131,
				max: 1636450,
			},
			longitude: {
				min: 2253393,
				max: 2253956,
			},
		},
		IG: {
			latitude: {
				min: 1635684,
				max: 1636080,
			},
			longitude: {
				min: 2253146,
				max: 2253472,
			},
		},
	},
	SM: {
		AS: {
			latitude: {
				min: 1635603,
				max: 1635830,
			},
			longitude: {
				min: 2253534,
				max: 2254212,
			},
		},
		P: {
			latitude: {
				min: 1635795,
				max: 1635867,
			},
			longitude: {
				min: 2254206,
				max: 2254462,
			},
		},
		SM: {
			latitude: {
				min: 1635362,
				max: 1635858,
			},
			longitude: {
				min: 2254452,
				max: 2255168,
			},
		},
		PS: {
			latitude: {
				min: 1635073,
				max: 1635640,
			},
			longitude: {
				min: 2255039,
				max: 2255747,
			},
		},
	},
	PP: {
		RIU: {
			latitude: {
				min: 1635632,
				max: 1635886,
			},
			longitude: {
				min: 2255558,
				max: 2255708,
			},
		},
		PP: {
			latitude: {
				min: 1635886,
				max: 1636286,
			},
			longitude: {
				min: 2255383,
				max: 2255801,
			},
		},
	},
	AL: {
		T: {
			latitude: {
				min: 1636394,
				max: 1636872,
			},
			longitude: {
				min: 2255634,
				max: 2255746,
			}
		},
		PB: {
			latitude: {
				min: 1636872,
				max: 1637400,
			},
			longitude: {
				min: 2255651,
				max: 2255858,
			},
		},
		BB: {
			latitude: {
				min: 1637400,
				max: 1637968,
			},
			longitude: {
				min: 2255671,
				max: 2256115,
			},
		},
	},
	WESC: {
		PRS: {
			latitude: {
				min: 1637968,
				max: 1638438,
			},
			longitude: {
				min: 2256033,
				max: 2256693,
			},
		},
		CAL: {
			latitude: {
				min: 1638438,
				max: 1638630,
			},
			longitude: {
				min: 2256530,
				max: 2256813,
			},
		},
		CFUND1: {
			latitude: {
				min: 1638630,
				max: 1638990,
			},
			longitude: {
				min: 2256530,
				max: 2256859,
			},
		},
		CFUND2: {
			latitude: {
				min: 1638990,
				max: 1639117,
			},
			longitude: {
				min: 2256530,
				max: 2256813,
			},
		},
	},
	MUD: {
		MUD1: {
			latitude: {
				min: 1639117,
				max: 1640442,
			},
			longitude: {
				min: 2255995,
				max: 2256962,
			},
		},
		MUD2: {
			latitude: {
				min: 1640633,
				max: 1640754,
			},
			longitude: {
				min: 2256115,
				max: 2256219,
			},
		},
		PR: {
			latitude: {
				min: 1640929,
				max: 1641056,
			},
			longitude: {
				min: 2256230,
				max: 2256274,
			},
		},
		PR2: {
			latitude: {
				min: 1641299,
				max: 1641429,
			},
			longitude: {
				min: 2256361,
				max: 2256436,
			},
		},
	},
	ML: {
		ML: {
			latitude: {
				min: 1641775,
				max: 1642176,
			},
			longitude: {
				min: 2257155,
				max: 2258301,
			},
		},
		MAD: {
			latitude: {
				min: 1641502,
				max: 1641943,
			},
			longitude: {
				min: 2256551,
				max: 2257155,
			},
		},
	},
	EASC: {
		PC1: {
			latitude: {
				min: 1640119,
				max: 1640473,
			},
			longitude: {
				min: -1, 
				max: -1,
			},
		},
		PC2: {
			latitude: {
				min: 1640473,
				max: 1641219,
			},
			longitude: {
				min: -1,
				max: -1,
			},
		},
		RdT1: {
			latitude: {
				min: 1641219,
				max: 1641784,
			},
			longitude: {
				min: 2252769,
				max: 2253765,
			},
		},
		RdT2: {
			latitude: {
				min: 1641784,
				max: 1642398,
			},
			longitude: {
				min: 2252769,
				max: 2253765,
			},
		},
		RdT3: {
			latitude: {
				min: 1642398,
				max: 1643191,
			},
			longitude: {
				min: 2252769,
				max: 2253765,
			},
		},
		PF: {
			latitude: {
				min: 1643191,
				max: 1643720,
			},
			longitude: {
				min: 2253586,
				max: 2253950,
			},
		},
		PARDA: {
			latitude: {
				min: 1643720,
				max: 1645008,
			},
			longitude: {
				min: 2253615,
				max: 2254135,
			},
		},
	},
	PdL: {
		PdL: {
			latitude: {
				min: 1645494,
				max: 1645766,
			},
			longitude: {
				min: 2253135,
				max: 2253910,
			},
		},
		FJ: {
			latitude: {
				min: 1645008,
				max: 1645303,
			},
			longitude: {
				min: 2253819,
				max: 2254294,
			},
		},
		TC: {
			latitude: {
				min: 1645649,
				max: 1645810,
			},
			longitude: {
				min: 2253028,
				max: 2253130,
			},
		},
	},
	NTH: {
		JP: {
			latitude: {
				min: 1642235,
				max: 1643086,
			},
			longitude: {
				min: 2258402,
				max: 2259167,
			},
		},
		FN: {
			latitude: {
				min: 1644248,
				max: 1644480,
			},
			longitude: {
				min: 2258573,
				max: 2258829,
			},
		},
		PAL: {
			latitude: {
				min: 1644829,
				max: 1645427,
			},
			longitude: {
				min: 2258549,
				max: 2258856,
			},
		},
		CADJ: {
			latitude: {
				min: 1648181,
				max: 1649911,
			},
			longitude: {
				min: 2253347,
				max: 2254344,
			},
		},
		FU: {
			latitude: {
				min: 1649911,
				max: 1650510,
			},
			longitude: {
				min: 2253347,
				max: 2254622,
			},
		},
	},
};

export default { coordinates, checkCoordInRange }