import moment from 'moment'

export const computeISNesNumber = ({ latitude = null, longitude = null, activityDate = null, activityTime = null, nestNumber = null }) =>
  `${latitude}${longitude}${activityDate}${activityTime}${nestNumber}`

const daysFromActivity = (date, days) =>
  moment(date).add(days, 'days').format('DD/MM/YYYY')

export const computeDaysFromActivity51 = ({ activityDate = null }) => activityDate ? daysFromActivity(activityDate, 51) : null

export const computeDaysFromActivity54 = ({ activityDate = null }) => activityDate ? daysFromActivity(activityDate, 54) : null

export const computeDaysFromActivity57 = ({ activityDate = null }) => activityDate ? daysFromActivity(activityDate, 57) : null

export const computeDaysFromActivity60 = ({ activityDate = null }) => activityDate ? daysFromActivity(activityDate, 60) : null

export const computeIncubationDays = ({ hatchDate = null, activityDate = null }) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const hatchTime = new Date(hatchDate).getTime()
  const activityTime = new Date(activityDate).getTime()
  return hatchDate && activityDate ? (hatchTime - activityTime) / oneDay : null
}

export const computeEmergedAlive = ({ fragments = 0, lft = 0, dft = 0, emergedDead = 0 }) => fragments - lft - dft - emergedDead
 // “Frags” - “Life Full Term” - “Dead Full Term” - “Emerged Dead”
// export const emergedAlive = ({ fragments = 0, lft = 0, dft = 0 }) => fragments - lft - dft

// UNHE + UHE + FFE + Predated
 // Frags + Life Pips + Dead Pips + UHNE + UHE + FFE + Predated
export const computeNewEggsCount = ({ fragments = 0, lp = 0, dp = 0, uhne = 0, uhe = 0, ffe = 0, predated = 0, undetermined = 0 }) => {
  return fragments + lp + dp + uhne + uhe + ffe + predated + undetermined;
}

export const computeEmergeSuccess = ({ emergeLive = 0, emergedDead = 0, newEggCount = 0 }) =>
  newEggCount === 0 ? "N/A" : (emergeLive + emergedDead) / newEggCount;

export const computeTotalUnhatched = ({ uhne = 0, uhe = 0, ffe = 0, predated = 0, undetermined = 0 }) =>
  uhne + uhe + ffe + predated + undetermined;

export const computeTotalHatch = ({ lft = 0, emergeLive = 0 }) => lft + emergeLive

export const computeHatchSuccess = ({ lft = 0, emergeLive = 0, newEggCount = 0 }) =>
  newEggCount ? computeTotalHatch({lft, emergeLive}) / newEggCount : null

export const computeTotalSuccess = ({ lft = 0, emergeLive = 0, lp = 0, newEggCount = 0 }) => newEggCount ? (computeTotalHatch({lft, emergeLive}) + lp) / newEggCount : null

export default {
  computeISNesNumber,
  computeDaysFromActivity51,
  computeDaysFromActivity54,
  computeDaysFromActivity57,
  computeDaysFromActivity60,
  computeIncubationDays,
  computeEmergedAlive,
  computeNewEggsCount,
  computeEmergeSuccess,
  computeTotalUnhatched,
  computeTotalHatch,
  computeHatchSuccess,
  computeTotalSuccess
}
