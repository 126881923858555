import React, { Component } from 'react'
import ReactForm from 'react-jsonschema-form'
import { schema, uiSchema } from './form'
import { getUsers, registerUser } from '../services/users'
import { Button, Modal } from 'react-bootstrap'
import './Users.css'

class Users extends Component {
  constructor (props) {
    super(props)

    this.state = {
      users: [],
      show: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount () {
    getUsers().then((users) => {
      console.log('AA', users)
      this.setState({ users })
    })
  }

  onSubmit (params) {
    console.log('onSubmit', params)
    registerUser(params.formData).then((user) => {
      this.state.users.push({ username: params.formData.username, email: params.formData.email })
      this.setState({ users: this.state.users })
      this.handleClose()
    })
  }

  // deleteUser (id) {
  //   removeUser(id)
  //   const i = this.state.users.findIndex((x) => x.id === id)
  //   const users = this.state.users
  //   users.splice(i, 1)

  //   this.setState({
  //     users
  //   })
  // }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return (
      <div>
        <Button variant="primary" className="new-button" onClick={this.handleShow}>
          New User
        </Button>

        <div className="list-group">
          {this.state.users.map((user) => {
            return (
              <div key={user.email} className="list-group-item">
                <div>{user.username} {user.email}</div>
              </div>
            )
          }
          )}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactForm schema={schema}
              uiSchema={uiSchema}
              onChange={(params) => console.log('change', params)}
              onSubmit={this.onSubmit}
              onError={(errors) => console.log('errors', errors)}
              showErrorList={false}
            >
              <Button type="submit">Create</Button>
            </ReactForm>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default Users
