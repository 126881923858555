import { request, enhanceUrl, paginate, filter, filterSeason } from './api'

export const getActivities = async ({ enhancers, pageSize, page, filters, season } = {}) =>
  request(
    paginate(
      filter(
        filterSeason(
          enhanceUrl('/api/activities', enhancers),
          season,
        ),
        filters,
      ),
      pageSize,
      page
    )
  );

export const getActivity = async (id, { enhancers } = {}) =>
  request(enhanceUrl(`/api/activities/${id}`, enhancers));

export const createActivity = async (data) =>
  request('/api/activities', { method: 'POST', body: JSON.stringify(data) })

export const updateActivity = async (id, data) =>
  request(`/api/activities/${id}`, { method: 'PUT', body: JSON.stringify(data) })

export const removeActivity = async (id) =>
  request(`/api/activities/${id}`, { method: 'DELETE' })

export default { getActivities, getActivity, createActivity, updateActivity, removeActivity }
