import 'whatwg-fetch'
import moment from 'moment';

const defaultOptions = {
  method: 'GET',
  credentials: 'include',
  headers: {
    'content-type': 'application/json'
  }
}

export const request = async (url, options = {}) => {
  const response = await fetch(url, { ...defaultOptions, ...options })
  if (response.ok) {
    if (response.status === 204) return
    return response.json()
  }

  const { redirectIfUnauthenticated = true } = options
  if (response.status === 401 && redirectIfUnauthenticated) {
    window.location.href = '/';
  } else {
    throw new Error(response.statusText)
  }
}

const getSeparator = url => url.indexOf('?') === -1 ? '?' : '&';

export const enhanceUrl = (url, enhancers = []) => {
  if (enhancers.length > 0) {
    const separator = getSeparator(url);
    return `${url}${separator}enhancers=${enhancers.join(',')}`;
  }
  return url;
};

export const filter = (url, filters = {}) => {
  if (Object.keys(filters).length > 0) {
    const separator = getSeparator(url);
    const mappedFilters = [];
    Object.entries(filters).forEach(([key, value]) => {
      if (key && value !== '' && value !== undefined) {
        mappedFilters.push(`${key}:${value}`);
      }
    });
    return `${url}${separator}filters=${mappedFilters.join('|')}`;
  }
  return url;
};

export const paginate = (url, pageSize = 10, page = 1) => {
  const separator = getSeparator(url);
  const skip = pageSize * (page - 1);
  const limit = pageSize;
  return `${url}${separator}skip=${skip}&limit=${limit}`;
};

export const filterSeason = (url, season) => {
  const separator = getSeparator(url);
  return `${url}${separator}season=${season || moment().format('YYYY')}`;
}


export default { request, enhanceUrl, paginate, filter, filterSeason }
