import { request } from './api'

export const getUsers = () =>
  request('/api/users')

export const getUser = async (id) =>
  request(`/api/users/${id}`)

export const getCurrentUser = async () =>
  request('/api/users/me', { redirectIfUnauthenticated: false })

export const registerUser = async (data) =>
  request('/api/users/register', { method: 'POST', body: JSON.stringify(data) })

export const login = async (data) =>
  request('/api/users/login', { method: 'POST', body: JSON.stringify(data) })

export const logout = async () =>
  request('/api/users/logout', { method: 'POST' })

export default { getUsers, getUser, getCurrentUser, registerUser }
