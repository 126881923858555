import {
  buildBeachDependencies,
  OPTIONS,
  getLabels
} from '../../utils/options'
import { coordinates, checkCoordInRange } from '../../utils/coordinates'
import { timepicker, latitude, longitude } from '../../utils/widgets'

export const beachDependencies = buildBeachDependencies('beach', 'location')

export const validateActivity = (formData, errors) => {
  if (formData.beach && formData.location && formData.coordinates.couldTakeCoordenates && coordinates[formData.beach] && coordinates[formData.beach][formData.location]) {
    const locationCoordinates = coordinates[formData.beach][formData.location];
    checkCoordInRange(formData.coordinates.latitude, locationCoordinates.latitude, errors.coordinates.latitude);
    checkCoordInRange(formData.coordinates.longitude, locationCoordinates.longitude, errors.coordinates.longitude);
  }
  return errors;
}

export const activityForm = {
  title: 'Activity',
  type: 'object',
  required: ['activityDate'],
  properties: {
    activityDate: {
      type: 'string',
      title: 'Activity Date',
      format: 'date',
    },
    activityTime: { type: 'string', title: 'Activity Time' },
    activityType: {
      'title': 'Activity Type',
      'type': 'string',
      'enum': OPTIONS.ACTIVITY_TYPES,
      'enumNames': getLabels("ACTIVITY_TYPES")
    },
    subActivityType: {
      'title': 'Sub-activity Type',
      'type': 'string',
      'enum': OPTIONS.SUBACTIVITY_TYPES,
      'enumNames': getLabels("SUBACTIVITY_TYPES")
    },
    beach: {
      'title': 'Beach',
      'type': 'string',
      'enum': OPTIONS.BEACH_OPTIONS,
      'enumNames': getLabels("BEACH_OPTIONS")
    },
    gpsNumber: {
      'title': 'GPS Number',
      'type': 'string',
      'enum': OPTIONS.GPS_OPTIONS,
      'enumNames': getLabels("GPS_OPTIONS")
    },
    waypoint: {
      'title': 'Waypoint',
      'type': 'number'
    },
    coordinates: {
      'title': '',
      '$ref': '#/definitions/coordinates',
    },
    hwm: {
      'title': 'High Water Mark',
      'type': 'number'
    },
    eggsSeen: {
      'title': 'Eggs seen',
      'type': 'boolean',
      'enumNames': ['Yes', 'No']
    },
    turtleSeen: {
      'title': 'Turtle Seen',
      'type': 'boolean',
      'enumNames': ['Yes', 'No']
    },
    // excavated: {
    //   'title': 'Nest excavated',
    //   'type': 'boolean',
    //   'enumNames': ['Yes', 'No'],
    // },
  },
  dependencies: {
    beach: {
      oneOf: beachDependencies
    },
    activityType: {
      oneOf: [{
      'properties': {
        activityType: {
          'enum': [ 'NEST' ]
        },
        newLocation: {
          'title': 'New Location',
          'type': 'string',
          'enum': OPTIONS.NEW_LOCATIONS,
          'enumNames': getLabels("NEW_LOCATIONS")
        },
        plasticNestMarker: {
          'title': 'Plastic Nest Number',
          'type': 'string'
        },
        woodenNestMarker: {
          'title': 'Wooden Nest Marker',
          'type': 'string'
        },
      }
    }]
    }
  },
  definitions: {
    coordinates: {
      type: 'object',
      properties: {
        couldTakeCoordenates: {
          title: 'Could you take coordinates?',
          type: 'boolean',
          default: true
        },
      },
      dependencies: {
        couldTakeCoordenates: {
          oneOf: [
            {
              properties: {
                couldTakeCoordenates: {
                  title: 'Could you take coordinates?',
                  type: 'boolean',
                  enum: [false],
                },
              }
            },
            {
              properties: {
                couldTakeCoordenates: {
                  title: 'Could you take coordinates?',
                  type: 'boolean',
                  enum: [true],
                },
                latitude: {
                  'title': 'Latitude',
                  'type': 'string'
                },
                longitude: {
                  'title': 'Longitude',
                  'type': 'string'
                },
              }
            },
          ]
        }
      }
    },
  }
};

export const uiActivityForm = {
  'ui:order': ['activityDate', 'activityTime', 'activityType',
    'subActivityType', 'beach', '*', 'gpsNumber', 'waypoint',
    'coordinates', 'hwm', 'eggsSeen', 'turtleSeen',
  ],
  activityDate: {
    'ui:field': 'rdp'
  },
  activityTime: {
    'ui:widget': timepicker
  },
  coordinates: {
    couldTakeCoordenates: {
      'ui:widget': 'radio',
    },
    latitude: {
      'ui:widget': latitude
    },
    longitude: {
      'ui:widget': longitude
    },
  },
  eggsSeen: {
    'ui:widget': 'radio'
  },
  turtleSeen: {
    'ui:widget': 'radio'
  },
  excavated: {
    'ui:widget': 'radio'
  },
  waypoint: {
    "ui:options": {
      "inputType": "number",
    }
  },
  hwm: {
    "ui:options": {
      "inputType": "number",
    }
  },
}

export default { activityForm, uiActivityForm, validateActivity }
