import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../services/users';
import logo from '../logo.webp'
import SeasonSelector from "./SeasonSelector";

const handleLogout = () => {
  logout().then(() => {
    window.location.href = '/';
  });
}

const Header = ({ isAdmin }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt='logo' height="40px" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">Home<span className="sr-only">(current)</span></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/patrols">Patrols</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/activities">Activities</Link>
            </li>
            {isAdmin &&
              <li className="nav-item">
                <Link className="nav-link" to="/users">Users</Link>
              </li>
            }
            {isAdmin &&
              <li className="nav-item">
                <Link className="nav-link" to="/patrollers">Patrollers</Link>
              </li>
            }
          </ul>
          <span>
            <SeasonSelector />
          </span>
          <span className="navbar-text">
            <button className="btn btn-link" onClick={handleLogout}>Log out</button>
          </span>
        </div>
      </nav>
    </div>
  )
}

export default Header
