import { patrollersAutosuggest } from '../utils/widgets'
import { OPTIONS } from '../utils/options'

export const schema = (allPatrollers) => {
  const allPatrollerIds = allPatrollers.map(x => x.id);
  const allPatrollerNames = allPatrollers.map(x => x.name);
  return {
    'type': 'object',
    'properties': {
      surveyDate: {
        type: 'string',
        title:'Survey Date',
        format: 'date'
      },
      code: {
        type: 'string',
        title: 'Patrol Code',
        enum: OPTIONS.PATROL_CODES,
        enumNames: OPTIONS.PATROL_CODES,
      },
      'patrollers': {
        'title': 'Patrol members',
        'type': 'array',
        'items': {
          'type': 'string',
          'enum': allPatrollerIds,
          'enumNames': allPatrollerNames,
        }
      }
    }
  }
}

export const uiSchema = {
  'ui:order': ['surveyDate', 'code', 'patrollers'],
  surveyDate: {
    'ui:field': 'rdp'
  },
  code: {
    'ui:widget': patrollersAutosuggest,
  },
  'patrollers': {
    'ui:options': {
      'orderable': false
    },
    items: {
      'ui:widget': patrollersAutosuggest
    }
  }
}

export default { schema, uiSchema }
