import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './PatrolList.css'
import 'react-datepicker/dist/react-datepicker.css'
import { getActivePatrols, archivePatrol } from '../services/patrols'
import { Button } from 'react-bootstrap'

const PATROLS_PAGE_SIZE = 10;

const storage = window.localStorage;

class PatrolList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      patrols: [],
      show: {},
      page: 1,
      showMoreEnabled: false,
    }

    this.toggleShow = this.toggleShow.bind(this)
    this.addActivity = this.addActivity.bind(this)
    this.loadPatrols = this.loadPatrols.bind(this)
    this.showMorePatrols = this.showMorePatrols.bind(this)
  }

  componentDidMount () {
    this.loadPatrols();
  }

  loadPatrols() {
    const opts = {
      enhancers: ['patrollers', 'numActivities'],
      pageSize: PATROLS_PAGE_SIZE,
      page: this.state.page,
      season: storage.getItem('season'),
    };
    getActivePatrols(opts).then(patrols =>
      this.setState({
        patrols: [...this.state.patrols, ...patrols],
        showMoreEnabled: patrols.length === PATROLS_PAGE_SIZE,
      }),
    );
  }

  showMorePatrols() {
    this.setState({ page: this.state.page + 1}, this.loadPatrols);
  }

  toggleShow (id) {
    if (this.state.show[id]) {
      this.setState({
        show: { ...this.state.show, id: !this.state.show[id] }
      })
    } else {
      this.setState({
        show: { ...this.state.show, id: true }
      })
    }
    this.setState({
      show: this.state.show
    })
  }

  async archivePatrol (id) {
    await archivePatrol(id);
    const { patrols } = this.state;
    this.setState({
      patrols: patrols.filter(p => p.id !== id),
    });
  }

  addActivity (patrolId) {
    this.props.history.push(`/patrol/${patrolId}/activity/new`);
  }
  render () {
    return (
      <div className="PatrolList">

        <Link to="/patrol/new" className="btn btn-primary new-button">New Patrol</Link>

        <div className="list-group">
          {this.state.patrols.map(patrol => (
            <div key={patrol.id} className="list-group-item">
              <div>
                <span className="badge badge-success">{patrol.code}</span>
                <div><b>Survey Date: </b> {patrol.surveyDate}</div>
                <div><b>Number of activities: </b> {patrol.numActivities}</div>
              </div>
              <div>
                <b>Members: </b> {patrol.patrollers.map(p => p.name).join(', ')}
              </div>
              <div>
                <Button className="first-button" variant="outline-primary" onClick={() => this.addActivity(patrol.id)}>Add patrol activity</Button>
                {this.props.isAdmin &&
                  <Button variant="outline-danger" onClick={() => this.archivePatrol(patrol.id)}>Archive</Button>
                }
              </div>
              {this.state.show[patrol.id] &&
                <div>
                  {Object.keys(patrol).map((key) =>
                    <div key={key}>
                      <b>{key}:</b><span>{patrol[key]}</span>
                    </div>
                  )}
                </div>
              }
            </div>
          ))}
          {this.state.showMoreEnabled &&
            <Button variant="outline-primary" onClick={this.showMorePatrols}>Show more</Button>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(PatrolList);
