import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import ActivityForm from '../ActivityForm/ActivityForm'
import Home from '../Home/Home'
import ActivityList from '../ActivityList/ActivityList'
import PatrolList from '../PatrolList/PatrolList'
import PatrolForm from '../PatrolForm/PatrolForm'
import Login from '../Login/Login'
import Users from '../Users/Users'
import Patrollers from '../Patrollers/Patrollers'
import NotFound from '../NotFound/NotFound'

const authenticationRequired = (isAuthenticated, isAdmin, location) => ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated
      ? <Component {...props} isAdmin={isAdmin} />
      : <Redirect to={`/login`} />
  )} />
)

const adminRequired = (isAuthenticated, isAdmin) => ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated
      ? isAdmin
        ? <Component {...props} />
        : <NotFound />
      : <Redirect to={`/login`} />
  )} />
)

const Main = ({ isAuthenticated, isAdmin, onLogin, location }) => {
  console.log({ isAuthenticated, isAdmin })
  const PrivateRoute = authenticationRequired(isAuthenticated, isAdmin, location)
  const AdminRoute = adminRequired(isAuthenticated, isAdmin)

  return (
    <main>
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <Route
          path='/login'
          component={(props) => isAuthenticated ? <Redirect to="/" /> : <Login onLogin={onLogin} {...props} />}
        />
        <PrivateRoute path='/activities' component={ActivityList} />
        <PrivateRoute path='/patrol/:patrolId/activity/new' component={ActivityForm} />
        <PrivateRoute path='/activity/:id/:form' component={ActivityForm} />
        <PrivateRoute path='/activity/:id' component={ActivityForm} />
        <PrivateRoute path='/patrols' component={PatrolList} />
        <PrivateRoute path='/patrol/new' component={PatrolForm} />
        <PrivateRoute path='/patrol/:id' component={PatrolForm} />
        <AdminRoute path='/users' component={Users} />
        <AdminRoute path='/patrollers' component={Patrollers} />
        <Route component={NotFound} />
      </Switch>
    </main>
  )
}

export default withRouter(Main)
