import { PIT_PATTERN, TAG_PATTERN } from '../../utils/options';
import { tag, patrollersAutosuggest } from '../../utils/widgets';

export const turtleForm = (patrollers) => {
  const patrollersIDs = patrollers.map(x => x.id);
  const patrollersNames = patrollers.map(x => x.name);
  return {
    title: 'Turtle Form',
    type: 'object',
    required: [],
    properties: {
      tagFoundL: {
        'title': '',
        '$ref': '#/definitions/tagFoundL',
      },
      tagFoundR: {
        'title': '',
        '$ref': '#/definitions/tagFoundR',
      },
      tagNewL: {
        'title': 'New Tag L',
        'type': 'string',
        'pattern': TAG_PATTERN,
      },
      tagNewR: {
        'title': 'New Tag R',
        'type': 'string',
        'pattern': TAG_PATTERN,
      },
      tagByL: {
        'title': 'Tagged By L',
        'type': 'string',
        'enum': patrollersIDs,
        'enumNames': patrollersNames
      },
      tagByR: {
        'title': 'Tagged By R',
        'type': 'string',
        'enum': patrollersIDs,
        'enumNames': patrollersNames
      },
      pitTagFound: {
        'title': '',
        '$ref': '#/definitions/pitTagFound',
      },
      pitTagNew: {
        'title': 'PIT New Tag',
        'type': 'string',
        'pattern': PIT_PATTERN,
      },
      pitTagBy: {
        'title': 'PIT Tagged by',
        'type': 'string',
        'enum': patrollersIDs,
        'enumNames': patrollersNames
      },
      turtleName: {
        'title': 'Turtle name',
        'type': 'string'
      },
      ccl: {
        'title': 'CCL',
        'type': 'number',
        'minimum': 0,
        'maximum': 120,
        'multipleOf': 0.1,
      },
      ccw: {
        'title': 'CCW',
        'type': 'number',
        'minimum': 0,
        'maximum': 100,
        'multipleOf': 0.1,
      },
      parasites: {
        'title': 'Parasites Found',
        'type': 'number',
        'enum': [0, 1],
        'enumNames': ['No', 'Yes']
      },
      dnaSample: {
        'title': 'DNA sample',
        'type': 'string'
      }
    },
    definitions: {
      pitTagFound: {
        type: 'object',
        properties: {
          pitTagFoundRadio: {
            'title': 'Did you find PIT Tag?',
            'type': 'string',
            'enum': ['found', 'not_found', 'not_checked'],
            'enumNames': ['Tag Found', 'Not Found', 'Not Checked'],
            default: 'found'
          },
        },
        dependencies: {
          pitTagFoundRadio: {
            oneOf: [
              {
                properties: {
                  pitTagFoundRadio: {
                    enum: ['not_found', 'not_checked'],
                  },
                }
              },
              {
                properties: {
                  pitTagFoundRadio: {
                    enum: ['found'],
                  },
                  pitTagFound: {
                    'title': 'PIT Tag Found',
                    'type': 'string',
                    'pattern': PIT_PATTERN,
                  }
                }
              }
            ]
          }
        }
      },
      tagFoundL: {
        type: 'object',
        properties: {
          tagFoundLRadio: {
            'title': 'Did you find Left Tag?',
            'type': 'string',
            'enum': ['found', 'not_found', 'not_checked'],
            'enumNames': ['Tag Found', 'Not Found', 'Not Checked'],
            default: 'found'
          },
        },
        dependencies: {
          tagFoundLRadio: {
            oneOf: [
              {
                properties: {
                  tagFoundLRadio: {
                    enum: ['not_found', 'not_checked'],
                  },
                }
              },
              {
                properties: {
                  tagFoundLRadio: {
                    enum: ['found'],
                  },
                  tagFoundL: {
                    'title': 'Tag Found L',
                    'type': 'string',
                    'pattern': TAG_PATTERN,
                  }
                }
              }
            ]
          }
        }
      },
      tagFoundR: {
        type: 'object',
        properties: {
          tagFoundRRadio: {
            'title': 'Did you find Right Tag?',
            'type': 'string',
            'enum': ['found', 'not_found', 'not_checked'],
            'enumNames': ['Tag Found', 'Not Found', 'Not Checked'],
            default: 'found'
          },
        },
        dependencies: {
          tagFoundRRadio: {
            oneOf: [
              {
                properties: {
                  tagFoundRRadio: {
                    enum: ['not_found', 'not_checked'],
                  },
                }
              },
              {
                properties: {
                  tagFoundRRadio: {
                    enum: ['found'],
                  },
                  tagFoundR: {
                    'title': 'Tag Found R',
                    'type': 'string',
                    'pattern': TAG_PATTERN,
                  }
                }
              }
            ]
          }
        }
      }
    }
  }
}


export const uiTurtleForm = {
  pitTagFound: {
    pitTagFoundRadio: {
      'ui:widget': 'radio',
    },
  },
  tagFoundL: {
    tagFoundLRadio: {
      'ui:widget': 'radio',
    },
    tagFoundL: {
      'ui:widget': tag
    }
  },
  tagFoundR: {
    tagFoundRRadio: {
      'ui:widget': 'radio',
    },
    tagFoundR: {
      'ui:widget': tag
    }
  },
  tagNewL: {
    'ui:widget': tag
  },
  tagNewR: {
    'ui:widget': tag
  },
  tagByL: {
    'ui:widget': patrollersAutosuggest
  },
  tagByR: {
    'ui:widget': patrollersAutosuggest
  },
  pitTagBy: {
    'ui:widget': patrollersAutosuggest
  },
  ccl: {
    "ui:options": {
      "inputType": "number",
    }
  },
  ccw: {
    "ui:options": {
      "inputType": "number",
    }
  },
}

export default { turtleForm, uiTurtleForm }
