export const observationsForm = {
  title: 'Observations on the activity',
  type: 'object',
  required: [],
  properties: {
    observations: {
      'title': 'Observations',
      'type': 'string'
    },
    completed: {
      'title': 'Activity completed',
      'type': 'boolean',
      'default': false
    }
  }
}

export const uiObservationsForm = {
  observations: {
    'ui:widget': 'textarea'
  }
}

export default { observationsForm, uiObservationsForm }
