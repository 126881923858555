import { LABELS } from './labels'

export const OPTIONS = {
  "ACTIVITY_TYPES": ['FCU', 'FCA', 'NEST', 'OTHER'],
  "SUBACTIVITY_TYPES": ['P', 'D', 'S'],
  "BEACH_OPTIONS": ['CF', 'SN', 'BN', 'AL', 'ML', 'PP', 'SM', 'WESC', 'EASC', 'MUD', 'NTH', 'PdL'],
  "LOCATIONS": {
    'CF': ['IG', 'PJ', 'S', 'CS', 'KB'],
    'SN': ['SN', 'B1', 'B2', 'B3', 'B4'],
    'BN': ['BN1', 'BN2', 'BN3'],
    'AL': ['BB', 'PB', 'T'],
    'ML': ['ML', 'MAD'],
    'PP': ['PP', 'RIU'],
    'SM': ['PS', 'SM', 'PO', 'AS'],
    'WESC': ['CAL', 'CFUND1', 'CFUND2', 'PRS'],
    'EASC': ['PAR', 'PF', 'RdT1', 'RdT2', 'RdT3', 'PC1', 'PC2'],
    'MUD': ['MUD1', 'MUD2', 'PR', 'PR2'],
    'NTH': ['JP', 'FON', 'PAL', 'CADJ'],
    'PdL': ['PdL', 'FJ', 'TC']
  },
  "GPS_OPTIONS": ['ON CALL', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
  "NEW_LOCATIONS": ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'TS'],
  "TRANSFER_METHODS": ['Walk', 'Quad', 'Car'],
  "TRANSFER_REASONS": ['Light', 'HWM', 'Flooding', 'Predation', 'Others'],
  "TRANSFER_DURATIONS": ['A', 'B', 'C', 'D'],
  "PATROL_CODES": [
    "CF1", "CF2", "CF3",
    "SN1", "SN2", "SN3",
    "AL1", "AL2", "AL3",
    "AL-MP", "CFUND",
    "ML1", "ML2",
    "MAD1", "MAD2", "SM-MP",
    "PdL1", "PdL2", "PdL3",
    "PC1", "PC2",
    "WESC-CENSUS", "EASC-CENSUS", "MUD-CENSUS", "NTH-CENSUS",
    "ON-CALL-1", "ON-CALL-2",
    "QMUL",
  ]
}

export const PIT_PATTERN = '^(\\d{15}|\\d{9}[A-Z])$'
export const TAG_PATTERN = '^(\\w{3}\\d{3})$'

export const buildBeachDependencies = (field, dependandField, dependandTitle = 'Location') => {
  return Object.keys(OPTIONS.LOCATIONS).map((beach) => {
    return {
      'properties': {
        [field]: {
          'enum': [ beach ]
        },
        [dependandField]: {
          title: dependandTitle,
          'type': 'string',
          'enum': OPTIONS.LOCATIONS[beach],
          'enumNames': getLabels("LOCATIONS", beach)
        }
      }
    }
  })
};

export const getLabels = (type, subtype = null) => {
  let labels = LABELS[type]
  let options = OPTIONS[type]
  if (subtype) {
    labels = labels[subtype]
    options = options[subtype]
  }
  return options.map((key) => labels[key])
}

export default {
  OPTIONS,
  buildBeachDependencies,
  getLabels
}
