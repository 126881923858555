import React, { Component } from 'react'
import Main from '../Main/Main'
import Header from '../Header/Header'
import { getCurrentUser } from '../services/users'
import './App.css'
import 'react-datepicker/dist/react-datepicker.css'

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: null,
      loading: true
    };
    this.getUser = this.getUser.bind(this);
  }

  componentWillMount () {
    this.getUser();
  }

  getUser() {
    getCurrentUser()
      .then(user => this.setState({ user }))
      .catch(() => {})
      .then(() => this.setState({ loading: false }));
  }

  render () {
    const { loading, user } = this.state
    if (loading) return null;

    return (
      <div className="App">
        {user && <Header isAdmin={user.admin} />}
        <div className="content">
          <Main
            isAuthenticated={!!user}
            isAdmin={user && user.admin}
            onLogin={this.getUser}
          />
        </div>
      </div>
    )
  }
}

export default App
