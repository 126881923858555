export const schema = {
  'type': 'object',
  'properties': {
    username: { type: 'string', title: 'Username' },
    email: { type: 'string', title: 'Email' },
    password: { type: 'string', title: 'Password' }
  }
}

export const uiSchema = {
  'ui:order': ['username', 'email', 'password'],
  'password': {
    'ui:widget': 'password'
  },
  'email': {
    'ui:widget': 'email'
  }
}

export default { schema, uiSchema }
